import PropTypes from 'prop-types';
import React from 'react';
import { Landing } from 'ui/screens';

const LandingPage = ({ location }) => {
  return <Landing location={location} />;
};

LandingPage.propTypes = {
  location: PropTypes.object,
};

LandingPage.defaultProps = {};

export default LandingPage;
